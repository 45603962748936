import React from 'react';

export default ({ children }) => {
  const dateTime = children;

  return (
    <time dateTime={dateTime}>
      {new Date(dateTime).toLocaleDateString(undefined, {
        dateStyle: 'medium',
      })}
    </time>
  );
};
