import React from 'react';
import { Helmet } from 'react-helmet';

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const slug = require('slug');

export default ({ article }) => {
  let introduction = documentToPlainTextString(
    JSON.parse(article.introduction.raw)
  );

  introduction =
    introduction.length > 160
      ? introduction.substring(0, 159) + '…'
      : introduction;

  return (
    <Helmet>
      <title>{article.title} • 🔭 Neighbour Institute</title>
      <meta name="description" content="" />

      <meta
        property="og:url"
        content={`https://neighbourinstitute.com/${slug(article.title)}`}
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={article.title} />
      <meta property="og:description" content={introduction} />

      {/* This the fun part */}
      {/* <meta property="og:image" content="" /> */}

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="neighbourinstitute.com" />
      <meta
        property="twitter:url"
        content={`https://neighbourinstitute.com/${slug(article.title)}`}
      />
      <meta name="twitter:title" content={article.title} />
      <meta name="twitter:description" content={introduction} />
      {/* <meta name="twitter:image" content="image.jpg" /> */}
    </Helmet>
  );
};
