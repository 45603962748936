export default (mimeType) => {
  let fileType = '';

  if (
    [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
    ].includes(mimeType)
  ) {
    fileType = 'DOC';
  } else if (
    [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ].includes(mimeType)
  ) {
    fileType = 'PPT';
  } else if (
    [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ].includes(mimeType)
  ) {
    fileType = 'XLS';
  } else if (['application/zip'].includes(mimeType)) {
    fileType = 'ZIP';
  } else if (['application/pdf'].includes(mimeType)) {
    fileType = 'PDF';
  }

  return fileType;
};
