import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import { UserContext } from '../lib/UserContext';

import Time from '../components/Time';
import SignIn from '../pages/signin';
import Head from './partials/article/_head';

import '../styles/article.scss';

import getIconByMimeType from '../components/helpers/getIconByMimeType';

export const query = graphql`
  query ($contentfulId: String!) {
    article: contentfulContentPage(contentful_id: { eq: $contentfulId }) {
      contentful_id
      title
      introduction {
        raw
      }
      createdAt
      updatedAt
      body {
        raw
        references {
          file {
            url
            contentType
            details {
              size
            }
          }
          contentful_id
          title
        }
      }
      metadata {
        tags {
          contentful_id
          name
        }
      }
    }
  }
`;

const ItemPage = ({ data }) => {
  const assetRenderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const fileId = node.data.target.sys.id;

        const file =
          data.article.body &&
          data.article.body.references &&
          data.article.body.references.filter(
            (r) => r.contentful_id === fileId
          )[0];

        return file ? (
          <a href={file.file.url}>
            {file.title} ({getIconByMimeType(file.file.contentType)},{' '}
            {(file.file.details.size / 1e6).toFixed(1)} MB)
          </a>
        ) : null;
      },
      [INLINES.ASSET_HYPERLINK]: (node) => {
        const fileId = node.data.target.sys.id;

        const file =
          node.data.body &&
          node.data.body.references &&
          node.data.body.references.filter((r) => r.contentful_id === fileId);
        return file ? (
          <a href={file.file.url}>
            {file.title} ({getIconByMimeType(file.file.contentType)},{' '}
            {(file.size / 1e6).toFixed(1)} MB)
          </a>
        ) : null;
      },
      [INLINES.HYPERLINK]: (node) => {
        let element;

        if (node.data.uri.includes('vimeo')) {
          const vimeoUri = node.data.uri;
          const { id, key } = vimeoUri.match(
            /^.+.com\/(?<id>[^\/]+)\/(?<key>[^\/]+)/
          ).groups;

          element = (
            <>
              <iframe
                src={`https://player.vimeo.com/video/${id}?h=${key}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title={node.content && node.content[0].value}
              ></iframe>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </>
          );
        } else {
          element = (
            <a href={node.data.uri}>{node.content && node.content[0].value}</a>
          );
        }

        return element;
      },
    },
  };

  useEffect(() => {
    const players = ['iframe[src*="youtube.com"]', 'iframe[src*="vimeo.com"]'];

    // Select videos
    const vids = document.querySelectorAll(players.join(','));

    // If there are videos on the page...
    if (vids.length) {
      vids.forEach((vid) => {
        const width = vid.getAttribute('width');
        const height = vid.getAttribute('height');
        const aspectRatio = height / width;
        const parentDiv = vid.parentNode;

        // Wrap it in a DIV
        const div = document.createElement('div');
        div.className = 'video__wrap';
        div.style.paddingBottom = aspectRatio * 100 + '%';
        parentDiv.insertBefore(div, vid);
        vid.remove();
        div.appendChild(vid);

        // Clear height/width
        vid.removeAttribute('height');
        vid.removeAttribute('width');
      });
    }
  }, []);

  const introduction = documentToReactComponents(
    JSON.parse(data.article.introduction.raw),
    assetRenderOptions
  );

  return (
    <UserContext.Consumer>
      {(context) => {
        return (
          <>
            <Head article={data.article} introduction={introduction[0]} />
            <div className="flex-1">
              <nav
                className="bg-white border-b border-gray-100 flex"
                aria-label="Breadcrumb"
              >
                <ol
                  role="list"
                  className="max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8"
                >
                  <li className="flex">
                    <div className="flex items-center">
                      <Link
                        to="/"
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <svg
                          className="flex-shrink-0 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        <span className="sr-only">Home</span>
                      </Link>
                    </div>
                  </li>

                  <li className="flex">
                    <div className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-6 h-full text-gray-200"
                        viewBox="0 0 24 44"
                        preserveAspectRatio="none"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                      </svg>
                      <Link
                        to="/explore"
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      >
                        Explore All
                      </Link>
                    </div>
                  </li>

                  <li className="flex">
                    <div className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-6 h-full text-gray-200"
                        viewBox="0 0 24 44"
                        preserveAspectRatio="none"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                      </svg>
                      <a
                        href="#"
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        aria-current="page"
                      >
                        {data.article.title}
                      </a>
                    </div>
                  </li>
                </ol>
              </nav>

              <div className="article__main-content relative py-16 bg-white overflow-hidden">
                <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                  <div
                    className="relative h-full text-lg max-w-prose mx-auto"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute top-12 left-full transform translate-x-32"
                      width="404"
                      height="384"
                      fill="none"
                      viewBox="0 0 404 384"
                    >
                      <defs>
                        <pattern
                          id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="4"
                            height="4"
                            className="text-gray-200"
                            fill="currentColor"
                          />
                        </pattern>
                      </defs>
                      <rect
                        width="404"
                        height="384"
                        fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                      />
                    </svg>
                    <svg
                      className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                      width="404"
                      height="384"
                      fill="none"
                      viewBox="0 0 404 384"
                    >
                      <defs>
                        <pattern
                          id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="4"
                            height="4"
                            className="text-gray-200"
                            fill="currentColor"
                          />
                        </pattern>
                      </defs>
                      <rect
                        width="404"
                        height="384"
                        fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                      />
                    </svg>
                    <svg
                      className="absolute bottom-12 left-full transform translate-x-32"
                      width="404"
                      height="384"
                      fill="none"
                      viewBox="0 0 404 384"
                    >
                      <defs>
                        <pattern
                          id="d3eb07ae-5182-43e6-857d-35c643af9034"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="4"
                            height="4"
                            className="text-gray-200"
                            fill="currentColor"
                          />
                        </pattern>
                      </defs>
                      <rect
                        width="404"
                        height="384"
                        fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                      />
                    </svg>
                  </div>
                </div>
                <div className="relative px-4 sm:px-6 lg:px-8">
                  <div className="text-lg max-w-prose mx-auto">
                    <h1>
                      <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                        <small>
                          Published <Time>{data.article.createdAt}</Time>
                        </small>
                      </span>
                      <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        {data.article.title}
                      </span>
                    </h1>
                    {data.article.metadata ? (
                      <div className="article__tags text-sm text-center py-4 font-medium text-indigo-600">
                        {data.article.metadata.tags.map((tag) => {
                          return (
                            <Link
                              to={`/explore?filters=${tag.contentful_id}`}
                              className="article__tag p-2 rounded-lg hover:bg-indigo-100"
                              key={tag.contentful_id}
                            >
                              {tag.name}
                            </Link>
                          );
                        })}
                      </div>
                    ) : undefined}
                    <div className="prose mt-5">
                      <h3 className="font-bold">Introduction</h3>
                    </div>
                    <div className="mt-2 text-xl text-gray-500 leading-8">
                      {introduction}
                    </div>
                  </div>

                  {context && context.user ? (
                    <>
                      <div className="prose">
                        <h3 className="font-bold mt-8">Main</h3>
                      </div>
                      <div className="mt-2 prose prose-indigo prose-lg text-gray-500 mx-auto">
                        {documentToReactComponents(
                          JSON.parse(data.article.body.raw),
                          assetRenderOptions
                        )}
                      </div>
                    </>
                  ) : (
                    <SignIn headingLevel={3} />
                  )}
                </div>
              </div>
            </div>
          </>
        );
      }}
    </UserContext.Consumer>
  );
};

export default ItemPage;
